import type { RendererProps } from '@atlaskit/renderer';

export const ttrSeverityTrackingOptions = (
	featureFlags?: object,
): RendererProps['analyticsEventSeverityTracking'] => {
	if (!featureFlags) {
		return undefined;
	}

	const optionsString = featureFlags['confluence.frontend.fabric.renderer.ttr-severity-tracking'];

	if (typeof optionsString === 'string') {
		try {
			return JSON.parse(optionsString);
		} catch (err) {
			if (
				process.env.CLOUD_ENV === 'staging' ||
				process.env.CLOUD_ENV === 'hello' ||
				process.env.CLOUD_ENV === 'branch'
			) {
				// eslint-disable-next-line no-console
				console.error(`Failed to parse ttr-severity-tracking`, err);
			}
		}
	}
};

export const unsupportedContentLevelsTrackingOptions = (
	featureFlags?: object,
): RendererProps['unsupportedContentLevelsTracking'] => {
	if (!featureFlags) {
		return undefined;
	}

	const optionsString =
		featureFlags['confluence.frontend.fabric.renderer.unsupported-content-levels-tracking'];

	if (typeof optionsString === 'string') {
		try {
			return JSON.parse(optionsString);
		} catch (err) {
			if (
				process.env.CLOUD_ENV === 'staging' ||
				process.env.CLOUD_ENV === 'hello' ||
				process.env.CLOUD_ENV === 'branch'
			) {
				// eslint-disable-next-line no-console
				console.error(`Failed to parse unsupported-content-levels-tracking`, err);
			}
			return undefined;
		}
	}
	return undefined;
};
