import gql from 'graphql-tag';

import { UserPermissionFragment } from './Fragments/UserPermissionFragment.fragment';

export const GlobalOperationsUserPermissionTypeQuery = gql`
	query GlobalOperationsUserPermissionTypeQuery {
		...UserPermissionFragment
	}

	${UserPermissionFragment}
`;
